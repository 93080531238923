import If from "components/If"
import Redirect from "components/Redirect"
import HomeLinks from "./components/HomeLinks"
import ProductPage from "./components/ProductPage"
import SearchApprovals from "./components/SearchApprovals"
import ResultApprovals from "./components/ResultApprovals"

import { useHome } from "./hooks"
import { HOME_LINKS } from "./constants"
import "./Home.scss"

const Home = () => {
	const isHomeEnabled = false

	const {
		userName,
		userDocument,
		setProductId,
		productId,
		showProducts,
		setShowProducts,
		handleNewConsult,
		preApprovalsError,
		preApprovalsResult,
		hasPreApprovalResult,
		handlePreApprovalsError,
		handlePreApprovalsResult,
	} = useHome()

	if (!isHomeEnabled) return <Redirect to="/upload" />

	return (
		<div className="home">
			<If
				condition={!showProducts}
				renderIf={
					<If
						condition={!hasPreApprovalResult}
						renderIf={
							<div className="home__search">
								<h1 className="home__title">Olá, {userName}!</h1>

								<p className="home__subtitle">Escolha um item para começar</p>

								<SearchApprovals
									setProductId={setProductId}
									preApprovalsError={preApprovalsError}
									handlePreApprovalsError={handlePreApprovalsError}
									handlePreApprovalsResult={handlePreApprovalsResult}
								/>
								<HomeLinks
									links={HOME_LINKS}
									setShowProducts={setShowProducts}
								/>
							</div>
						}
						renderElse={
							<ResultApprovals
								userName={userName}
								setProductId={setProductId}
								userDocument={userDocument}
								handleNewConsult={handleNewConsult}
								preApprovalsResult={preApprovalsResult}
							/>
						}
					/>
				}
				renderElse={
					<ProductPage
						productId={productId}
						setShowProducts={setShowProducts}
					/>
				}
			/>
		</div>
	)
}

export default Home
