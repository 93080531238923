import auth from "utils/auth"
import { useState } from "react"

export const useHome = () => {
	const { name } = auth.getUser()

	const [preApprovalsResult, setPreApprovalsResult] = useState([])
	const [preApprovalsError, setPreApprovalsResultError] = useState(false)
	const [userDocument, setUserDocument] = useState(null)
	const [showProducts, setShowProducts] = useState(false)
	const [productId, setProductId] = useState(null)

	const hasPreApprovalResult = !!preApprovalsResult?.length

	const handlePreApprovalsResult = ({ approvals, document }) => {
		setPreApprovalsResult(approvals)
		setUserDocument(document)
	}

	const handleNewConsult = () => {
		setPreApprovalsResultError(false)
		setPreApprovalsResult([])
		setUserDocument(null)
	}

	const handlePreApprovalsError = (message) => {
		setPreApprovalsResultError(message)
	}

	return {
		userName: name,
		setShowProducts,
		showProducts,
		userDocument,
		setProductId,
		productId,
		handleNewConsult,
		preApprovalsError,
		preApprovalsResult,
		hasPreApprovalResult,
		handlePreApprovalsError,
		handlePreApprovalsResult,
	}
}
