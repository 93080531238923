import * as Yup from "yup"
import { _validators } from "@redsales/ds/utils"

export const values = {
	document: "",
}

export const validation = Yup.object().shape({
	document: Yup.string()
		.required("Campo obrigatório")
		.test("validDocument", "Documento inválido", (value) =>
			_validators.cpf(value)
		),
})
